
import { Link } from '@material-ui/core';
import { DataGridPro, GridDensityTypes, GridToolbar } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import incidentsColumns from '../components/IncidentsColumns';
import { PageContainer } from '../components/PageContainer';
import { OpenRecentIncidentsData } from '../models/DataSource';
import { RetrievedIncidentsData } from '../models/RetrievedData';
import { dcName, isDcNameValid } from '../models/SearchParams';

import jsPDF from "jspdf"

// import tokml from 'tokml';
const tokml = require('tokml')

function OpenIncidentsComponent() {
	const { data:incidentsData, isLoading:incidentsLoading} = OpenRecentIncidentsData();
	const [incidentData, setIncidentData] = useState<any[]>([])

	useEffect(() => {
		if (incidentsData && incidentsData[0].data != null) {
			const retrieved_data = new RetrievedIncidentsData(incidentsData[0]);	
			setIncidentData(retrieved_data.data);
		}
	}, [incidentsData])
	
	const toolbarControl = { Toolbar: GridToolbar }

	function downloadKML() {
		console.log(dcName)
		// console.log(tokml)
		const features:any = []
		incidentData.forEach((x) => {
			console.log(x)
			const newFeature = {
				type: 'Feature',
				properties: {
					name: x.name,
				},
				geometry: {
					coordinates: [x.longitude * -1, x.latitude],
					type: 'Point'
				},
                            
			}
			features.push(newFeature)

		})
		const newGeojson = {
			type: 'FeatureCollection',
			name: 'incidents',
			features: features
		}
		const incidentsKml = tokml(newGeojson)
		console.log(incidentsKml)

		const file = new File([incidentsKml], `${dcName} Open/Recent Incidents.kml`, {
			type: 'text/plain',	
		})
		  
		function download() {
			const link = document.createElement('a')
			const url = URL.createObjectURL(file)
			
			link.href = url
			link.download = file.name
			document.body.appendChild(link)
			link.click()
			
			document.body.removeChild(link)
			window.URL.revokeObjectURL(url)
		}
		download()

		// const doc = new jsPDF('p', 'pt')
		// doc.text(incidentsKml, 0, 0)
		// doc.save('test.kml')
		



	}
	return (
		<PageContainer
			pageTitle='Open/Recent Incidents'
		>
			<DataGridPro 
				initialState={{
				sorting: {sortModel: [{ field: 'date', sort: 'desc' }],},}}
				getRowId={(r) => r.uuid}
				rows={incidentData} 
				columns={incidentsColumns(true)} 
				getRowHeight={() => 'auto'}
				density={GridDensityTypes.Compact}
				headerHeight={30}
				hideFooter
				loading={incidentsLoading}
				components={toolbarControl}
			/>
		</PageContainer>
	);
}

export const OpenIncidentsPage: React.FunctionComponent = React.memo(OpenIncidentsComponent);
