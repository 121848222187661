
import { DataGridPro, GridDensityTypes, GridToolbar } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import incidentsColumns from '../components/IncidentsColumns';
import { MonthDropdown } from '../components/MonthDropdown';
import { PageContainer } from '../components/PageContainer';
import { dcName } from '../models/SearchParams';


function IncidentsByMonthComponent() {
	const [filteredIncidents, setFilteredIncidents] = useState<any[]>([])
	const [loadingPage, setLoadingPage] = useState<boolean>(false)
	const [month, setMonth] = useState<number>(-1)
	const [monthData, setMonthData] = useState<any>({})

	const fetchData = async (params: any) => {
		const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/centers/${params.dcName}/incidents?fromDate=${params.fromDate}&toDate=${params.toDate}`)
		const data = await response.json()
		if (response.status < 200 || response.status >= 300) {
			setFilteredIncidents([])
		} else if (data && data[0]?.data) {
			setFilteredIncidents(data[0].data)
			setLoadingPage(false)
		} else {
			setFilteredIncidents([]);
			setLoadingPage(false);
		}
	}

	useEffect(() => {
        if (monthData && Object.keys(monthData).length > 0) {
            const params = {
                dcName: dcName,
                fromDate: monthData.firstDay,
                toDate: monthData.lastDay,
            }
			fetchData(params)
			setLoadingPage(true)
        } else {
			setFilteredIncidents([])
		}
	}, [monthData])

	let id = 0;

	const toolbarControl = { Toolbar: GridToolbar }

	return (
		
        <PageContainer
			pageTitle='Incident by Month'
		>
            <div style={{marginLeft: '15px'}}>
                <MonthDropdown id={month} onChange={(e: any) => setMonth(e.target.value)} onChangeData={setMonthData}/>
            </div>
            <DataGridPro 
                initialState={{
                sorting: {sortModel: [{ field: 'date', sort: 'desc' }],},}}
                getRowId={(r) => id++}
                rows={filteredIncidents} 
                columns={incidentsColumns(false)} 
                getRowHeight={() => 'auto'}
                density={GridDensityTypes.Compact}
                headerHeight={30}
                hideFooter
                loading={loadingPage}
                components={toolbarControl}
            />
		</PageContainer>
	);

}
export const incidentMonthPage: React.FunctionComponent = React.memo(IncidentsByMonthComponent);
