/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import {
	makeStyles,
	Theme,
	createStyles,
	useTheme,
} from '@material-ui/core/styles';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { DrawerWidth } from '../Constants';
import { Divider, Drawer, DrawerProps, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { sidebarMenu } from '../assets/sidebarMenu';
import { ISidebarMenuNode } from '../models/SidebarMenuNode';
import { DownloadKMLFunction } from '../pages/DownloadKML';
import { OpenRecentIncidentsData } from '../models/DataSource';
import { RetrievedIncidentsData } from '../models/RetrievedData';
import { IIncidentsData } from '../models/IncidentData';
import { downloadKMLSidebarName } from '../store/Constants';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		hide: {
			display: 'none',
		},
		drawer: {
			width: DrawerWidth,
			flexShrink: 0,
		},
		drawerPaper: {
			width: DrawerWidth,
		},
		drawerHeader: {
			display: 'flex',
			alignItems: 'center',
			padding: theme.spacing(0, 1),
			...theme.mixins.toolbar,
			justifyContent: 'flex-end',
		},
		nested: {
			paddingLeft: theme.spacing(2),
		},
	})
);

export interface AppDrawerProps extends DrawerProps {
	menuDrawerOpen: boolean;
	setMenuDrawerOpen: any;
}

function AppDrawerComponent(props: AppDrawerProps) {

    const { menuDrawerOpen, setMenuDrawerOpen } = props;
    const classes = useStyles()
    const theme = useTheme();
    const history = useHistory();


    function handleDrawerClose() {
		setMenuDrawerOpen(false);
	}

    const menuDrawerData: any = {
        title: 'Menu Root',
        path: '/',
            children: [
            {
                title: 'Loading Menu...',
                path: '/',
            },
        ]
    };

    function createMenuNodes(rootNode: any) {
        const menuElems: JSX.Element[] = [];
        let nodeCtr = 0;
        rootNode.children?.map((subNode: ISidebarMenuNode) => {
            const nodeKey = rootNode.title + subNode.title + (nodeCtr++);
            if (subNode)
                if (subNode.children && subNode.children.length > 0) {
                    // Recurse on the subnodes
                    menuElems.push(
                        <TreeItem key={nodeKey} nodeId={nodeKey} 
                        label={subNode.title}>
                            {createMenuNodes(subNode)}
                        </TreeItem>
                    )
                } else {
                    menuElems.push(
                        <TreeItem key={nodeKey} nodeId={nodeKey} role='link'
                        label={subNode.title} onClick={(e) => handleOnClick(subNode)}/>
                    )
                }
        })
        return menuElems
    }

    function createMenu() {
        const rootMenuNode = sidebarMenu ? sidebarMenu : menuDrawerData;
        return [...createMenuNodes(rootMenuNode)]
    }

    //get incident data to send to sidebar click function
    const { data:incidentsData, isLoading:incidentsLoading} = OpenRecentIncidentsData();
    const [incidentData, setIncidentData] = useState<IIncidentsData[]>([])

    useEffect(() => {
        if (incidentsData && incidentsData[0].data != null) {
            const retrieved_data = new RetrievedIncidentsData(incidentsData[0]);	
            setIncidentData(retrieved_data.data);
        }
    }, [incidentsData])

    function handleOnClick(item: ISidebarMenuNode) {
        // if clicking on the kml download, run this function
        if (item.title === downloadKMLSidebarName) {
            DownloadKMLFunction(incidentData)
        } else {
            // otherwise, navigate to the path
            history.push(item.path + history.location.search);
            setMenuDrawerOpen(false);
        }

    }


    return (
        <Drawer
            className={classes.drawer}
            variant='persistent'
            anchor='left'
            open={menuDrawerOpen}
            classes={{
                paper: classes.drawerPaper
            }}
        >
           <div key='drawerHeader' className={classes.drawerHeader}>
				<IconButton onClick={handleDrawerClose}>
					{theme.direction === 'ltr' ? (
						<ChevronLeftIcon />
					) : (
						<ChevronRightIcon />
					)}
				</IconButton>
			</div>
            <Divider />
                <TreeView
                    aria-label='Page Menu'
                    defaultCollapseIcon={<ExpandMoreIcon/>}
                    defaultExpandIcon={<ChevronRightIcon/>}
                    sx={{height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto', overflowX: 'hidden'}}>
                    {createMenu()}
                </TreeView>
            <Divider />
        </Drawer>
        

    )
}

export const AppDrawer: React.FunctionComponent<AppDrawerProps> =
	React.memo(AppDrawerComponent);
