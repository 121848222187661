
import { IIncidentsData } from '../models/IncidentData';
import { dcName } from '../models/SearchParams';
const tokml = require('tokml')

export function DownloadKMLFunction(incidentData:IIncidentsData[]) {

	const features:any = []
	incidentData.forEach((x:IIncidentsData) => {
		const newFeature = {
			type: 'Feature',
			properties: {
				name: x.name,
			},
			geometry: {
				coordinates: [parseFloat(x.longitude) * -1, x.latitude],
				type: 'Point'
			},
						
		}
		features.push(newFeature)

	})
	const newGeojson = {
		type: 'FeatureCollection',
		name: 'incidents',
		features: features
	}
	const incidentsKml = tokml(newGeojson)

	const file = new File([incidentsKml], `${dcName} Open/Recent Incidents.kml`, {
		type: 'text/plain',	
	})
		
	const link = document.createElement('a')
	const url = URL.createObjectURL(file)
	
	link.href = url
	link.download = file.name
	document.body.appendChild(link)
	link.click()
	
	document.body.removeChild(link)
	window.URL.revokeObjectURL(url)

	return null

}