import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from "@mui/material";
import { GridColDef, GridColTypeDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { IFireStatus } from "../models/FireStatus";
import { IFiscalData } from "../models/FiscalData";
import { dateTimeFormatter, dateTimeFormatterStr } from "../utils/dateUtils";
import { coordToDegreesDecimalMinutes, Free2DDeg } from "../utils/geoUtils";
import { Link } from "react-router-dom";

export default function incidentsColumns(openIncPage: boolean): GridColDef[] {
    const formattedDate: GridColTypeDef = {
        type: 'string',
        valueFormatter: ({ value }) => dateTimeFormatter(new Date(String(value))),
    };

    return ([
            { 
                field: 'date', 
                headerName: 'Local Date/Time', 
                ...formattedDate,
                valueFormatter: ({ value }) => dateTimeFormatterStr(String(value)),
                minWidth: 110,
                width: 140,
                type: 'dateTime',
            },
            { 
                field: 'inc_num', 
                headerName: 'Inc#', 
                valueGetter: (params: GridValueGetterParams) => {
                    const fiscal = openIncPage ? params.row.fiscal_data as IFiscalData : JSON.parse(params.row.fiscal_data) as IFiscalData;
                    let retVal = '';
                    // Add unit and inc num.
                    retVal = fiscal.wfdssunit?.length > 0 ? fiscal.wfdssunit + "-" + fiscal.inc_num : fiscal.inc_num;
                    return retVal;
                },
                width: 120,
            },
            { 
                field: 'fiscal_data', 
                headerName: 'Fiscal', 
                valueGetter: (params: GridValueGetterParams) =>{
                    const fiscal = openIncPage ?
                    params.row.fiscal_data as IFiscalData : 
                    JSON.parse(params.row.fiscal_data) as IFiscalData;

                    let retVal = '';
                    // Add job codes
                    if (fiscal.fs_job_code?.length > 0) {
                        retVal = retVal + fiscal.fs_job_code;
                    }
                    if (fiscal.fire_code?.length > 0) {
                        if (fiscal.fs_job_code?.length > 0) retVal = retVal + " ";
                        retVal = retVal + fiscal.fire_code;
                    }
                    if (fiscal.fs_override?.length > 0) {
                        if (fiscal.fire_code?.length > 0 || fiscal.fs_job_code?.length > 0) retVal = retVal + " ";
                        retVal = retVal + "(" + fiscal.fs_override + ")";
                    }
        
                    // Add misc codes
                    if(fiscal.abcd_misc?.trim().length > 0){
                        retVal = retVal + "\n" +  fiscal.abcd_misc;
                    }
                    
                    // Add state codes
                    if(fiscal.state_fiscal_code?.trim().length > 0){
                        retVal = retVal + "\nState: " +  fiscal.state_fiscal_code;
                    }
                    // Add comments.
                    if(fiscal.fiscal_comments?.trim().length > 0){
                        retVal = retVal + "\nOther: " + fiscal.fiscal_comments;
                    }
        
                    return retVal;
                },
                width: 150,
            },
            { 
                field: 'type', 
                headerName: 'Incident Type', 
                width: 150 
            },
            { 
                field: 'name', 
                headerName: 'Name', 
                width: 150 ,
            },
            { 
                field: 'location', 
                headerName: 'Location', 
                width: 150 ,
            },
            { 
                field: 'latitude', 
                headerName: 'Lat/Lon',
                renderCell: (params: GridRenderCellParams) => {
                    //check for LE incident and just display **** instead of link
                    if (params.row.latitude === '*******'){
                        return (params.row.latitude)
                    } else if (params.row.latitude && params.row.longitude && Number(params.row.latitude) !== 0 && Number(params.row.longitude) !== 0){
                        const latLon = `${params.row.latitude}, ${params.row.longitude * -1}`;
                        const dDmLatLon = `${coordToDegreesDecimalMinutes(Free2DDeg(params.row.latitude?.toString()))} x ${coordToDegreesDecimalMinutes(Free2DDeg(params.row.longitude?.toString()))}`
                        return (
                            <div style={{display: 'flex', flexDirection: 'row', minWidth: '170px', justifyContent: 'space-between'}}>
                                <a 
                                    target='_blank'
                                    href={`http://maps.google.com/maps?z=12&t=m&q=loc:${params.row.latitude}+${-1 * (params.row.longitude)}`}
                                    title='See this location on Google Maps'
                                >
                                    {latLon}<br/>{dDmLatLon}
                                </a>
                                <IconButton 
                                    style={{padding: '0', marginLeft: '.5em'}}
                                    title='Copy coordinates to clipboard'
                                    onClick={() => navigator.clipboard.writeText(latLon)}
                                >
                                    <ContentCopyIcon/>
                                </IconButton>
                            </div>
                        )
                    }
                },
                width: 200,
                minWidth: 200 
            },
            { 
                field: 'fire_status', 
                headerName: 'Status', 
                valueGetter: ({value}) => {
                    // Fire status proceeds in the order: contained > controlled > out.
                    // if fire is out, it is also controlled and contained.
                    const stat = openIncPage ? value as IFireStatus : (value !== '*******' ? JSON.parse(value) as IFireStatus : value as IFireStatus);
                    if (stat.out) {
                        if (stat.out === '*******') return stat.out;
                        return 'Out'
                        // if fire is controlled, it is also contained.
                    } else if (stat.control) {
                        return 'Controlled'
                    } else if (stat.contain) { 
                        return 'Contained'
                    } else if (stat as any === '*******') {
                        return stat
                    } else {
                        return null
                    }
                },
                width: 75 
            },
            { 
                field: 'resources', 
                headerName: 'Resources', 
                width: 150 ,
                valueFormatter: ({ value }) => value.join(', ')
            },
            { 
                field: 'acres', 
                headerName: 'Acres', 
                width: 150 
            },
            { 
                field: 'webComment', 
                headerName: 'Web Comment', 
                width: 150 
            },
        ])
}
