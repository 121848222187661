import { ISidebarMenuNode } from "../models/SidebarMenuNode";
import { downloadKMLSidebarName } from "../store/Constants";


export const sidebarMenu:ISidebarMenuNode = {
    "path": '/',
    "title": "WildWeb",
    "children": [
    {
        "path": '/centers',
        "title": "Home (List Centers)"
    },
    {
        "path": '/incidents',
        "title": 'Open/Recent Incidents'
    },
    {
        "path": "/incidentByType",
        "title": "Incidents by Type"
    },
    {
        "path": '/incidentByMonth',
        "title": 'Incidents By Month'
    },
    {
        "path": "/calendarYear",
        "title": "Calendar Year Summary"
    },
    {
        "path": "/resourceStatus",
        "title": "Resource Status"
    },
    {
        "path": "/",
        "title": downloadKMLSidebarName
    },
    {
        "path": "/about",
        "title": "About"
    }]
}